<template>
  <!--
    The main menu. Used to route the user to a certain view.
  -->
  <div
    ref="mainMenu"
    class="mainMenu m-grid__item m-aside-left m-aside-left--skin-light py-4"
  >
    <ul>
      <template v-for="(item, index) in menuItems">
        <template v-if="item.type == 'grafana' && !item.isHidden">
          <li
            :key="`menuItemListItem-${ index }`"
            class="px-4 py-3 alt-pointer"
          >
            <div
              @click="openInGrafana"
            >
              <font-awesome-icon
                class="mr-2 gray"
                :icon="item.icon" 
              />
              <span>{{ item.text }}</span>
            </div>
          </li>
        </template>

        <template v-if="item.type == 'link' && !item.isHidden">
          <router-link
            :key="`menuItemRouterLink-${ index }`"
            :to="item.to"
            tag="li"
            class="alt-pointer"
            exact-active-class="exactActiveClass"
          >
            <div @click.left.exact="item.toggle ? toggleAsideMenuMobile() : null">
              <a class="px-4 py-3">
                <font-awesome-icon
                  class="mr-2 gray"
                  :icon="item.icon" 
                />
                <span>{{ item.text }}</span>
              </a>
            </div>
          </router-link>
        </template>

        <template v-if="item.type == 'noteLink' && !item.isHidden">
          <router-link
            :key="`menuItemRouterLink-${ index }`"
            :to="item.to"
            tag="li"
            class="alt-pointer"
            exact-active-class="exactActiveClass"
          >
            <div @click.left.exact="item.toggle ? toggleAsideMenuMobile() : null">
              <a class="px-4 py-3">
                <div
                  v-if="store_getReadNoteLength && store_getReadNoteLength > 0"
                  class="number"
                >
                  {{ store_getReadNoteLength }}
                </div>
                <font-awesome-icon
                  class="mr-2 gray"
                  :icon="item.icon" 
                />
                <span>{{ item.text }}</span>
              </a>
            </div>
          </router-link>
        </template>

        <template v-if="item.type == 'parent' && !item.isHidden">
          <li
            :key="`menuItemListItem-${ index }`"
            :data-target="`#${ item.target }`"
            :aria-controls="item.target"
            data-toggle="collapse"
            aria-expanded="false"
            class="alt-pointer collapsed"
          >
            <div
              class="px-4 py-3"
              @click.left.exact="item.toggle ? toggleAsideMenuMobile() : null"
            >
              <font-awesome-icon
                class="mr-2 gray"
                :icon="item.icon" 
              />
              <span>{{ item.text }}</span>
              <font-awesome-icon
                class="float-right gray"
                :transform="{ rotate: 180 }"
                icon="caret-up"
              />
            </div>
          </li>
        </template>

        <template v-if="item.type == 'parentLink' && !item.isHidden">
          <router-link
            :key="`menuItemRouterLink-${ index }`"
            :to="item.to"
            :data-target="`#${ item.target }`"
            :aria-controls="item.target"
            data-toggle="collapse"
            aria-expanded="false"
            tag="li"
            class="alt-pointer collapsed"
            exact-active-class="exactActiveClass"
          >
            <div @click.left.exact="item.toggle ? toggleAsideMenuMobile() : null">
              <a class="px-4 py-3">
                <font-awesome-icon
                  class="mr-2 gray"
                  :icon="item.icon" 
                />
                <span>{{ item.text }}</span>
                <font-awesome-icon
                  class="float-right gray"
                  :transform="{ rotate: 180 }"
                  icon="caret-up"
                />
              </a>
            </div>
          </router-link>
        </template>

        <template v-if="item.type == 'list' && !item.isHidden">
          <ul
            :id="item.id"
            :key="`menuItemUnorderedList-${ index }`"
            class="collapse"
          >
            <router-link
              v-for="(listItem, listIndex) in item.items"
              :key="`submenuItemRouterLink-${ listIndex }`"
              :to="listItem.to"
              tag="li"
              class="alt-pointer"
              exact-active-class="exactActiveClass"
            >
              <div
                v-if="!listItem.isHidden"
                @click.left.exact="listItem.toggle ? toggleAsideMenuMobile() : null"
              >
                <a class="px-4 py-3 ml-3">
                  <font-awesome-icon
                    class="mr-2 gray"
                    :icon="listItem.icon" 
                  />
                  <span>{{ listItem.text }}</span>
                </a>
              </div>
            </router-link>
          </ul>
        </template>

        <template v-if="item.type == 'hr' && !item.isHidden">
          <hr :key="`menuItemHr-${ index }`">
        </template>
      </template>

      <li
        v-if="displayLogout"
        class="alt-pointer"
      >
        <div @click.left.exact="authenticationLogout()">
          <a class="px-4 py-3">
            <font-awesome-icon
              class="mr-2"
              icon="sign-out-alt"
              style="color: #a8aabb"
            />
            <span>{{ $t('logout') }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { mobileMixin } from '@/mixins/mobileMixin';
import { mapGetters } from 'vuex';

export default {
  name: "Menu",
  mixins: [
    authenticationMixin,
    mobileMixin
  ],
  data () {
    return {
      displayLogout: false
    }
  },
  computed: {
    ...mapGetters([
      'store_getReadNoteLength'
    ]),
    menuItems () {
      return [
        {
          type: 'link',
          to: '/installation',
          text: this.$t('menu.installations'),
          icon: 'hdd',
          toggle: true,
          isHidden: !((this.authenticationHasRole('scope_staff') || this.authenticationHasRole('scope_customer')) && (this.authenticationHasRole('firstlevel') ||
            this.authenticationHasRole('admin') || this.authenticationHasRole('zerolevel') || this.authenticationHasRole('secondlevel')))
        },
        {
          type: 'link',
          to: '/peak',
          text: 'Peaksolution',
          icon: 'mountain',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_peak') && 
          (this.authenticationHasRole('firstlevel') || this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'link',
          to: '/login-data',
          text: 'Login Data',
          icon: 'lock',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('logindata_all') && 
          (this.authenticationHasRole('firstlevel') || this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
        },
         {
          type: 'link',
          to: '/problem',
          text: this.$t('menu.problems'),
          icon: 'exclamation-triangle',
          toggle: true,
          isHidden: !(this.authenticationHasRole('secondlevel') || (this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin'))))
        },
        {
          type: 'link',
          to: '/notes',
          text: this.$t('menu.notes'),
          icon: 'history',
          toggle: true,
          isHidden: !(this.authenticationHasRole('secondlevel') || (this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin'))))
        },
        {
          type: 'link',
          to: '/support-requests',
          text: 'Support Requests',
          icon: 'clipboard-check',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') || this.authenticationHasRole('scope_customer'))
        },
        {
          type: 'link',
          to: '/changelogs',
          text: 'Changelogs',
          icon: 'clock',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'noteLink',
          to: '/inbox',
          text: 'Inbox',
          icon: 'envelope-open',
          toggle: true
        },
        {
          type: 'link',
          to: '/outbox',
          text: 'Outbox',
          icon: 'envelope-open-text',
          toggle: true
        },
        {
          type: 'link',
          to: '/resorts',
          text: this.$t('menu.resorts'),
          icon: 'map-marked-alt',
          toggle: true,
          isHidden: !((this.authenticationHasRole('scope_staff') || this.authenticationHasRole('scope_customer')) && (this.authenticationHasRole('firstlevel') ||
            this.authenticationHasRole('admin') || this.authenticationHasRole('secondlevel')))
        },
        {
          type: 'grafana',
          text: this.$t('homeView.dashboard'),
          icon: 'tachometer-alt',
          toggle: true,
          isHidden: !((this.authenticationHasRole('scope_staff') || this.authenticationHasRole('scope_customer')) && (this.authenticationHasRole('firstlevel') ||
            this.authenticationHasRole('admin') || this.authenticationHasRole('secondlevel')))
        },
        {
          type: 'link',
          to: '/contacts',
          text:this.$t('menu.contacts'),
          icon: 'address-book',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'link',
          to: '/cloudstorage',
          text:this.$t('menu.cloudStorage'),
          icon: 'folder',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('menu.centralDeviceManagement'),
          icon: 'tablet-alt',
          toggle: false,
          target: 'centralDeviceManagementSubmenu',
          isHidden: this.authenticationHasRole('zerolevel'),
        },
        {
          type: 'list',
          id: 'centralDeviceManagementSubmenu',
          items: [
            {
              type: 'link',
              to: '/centraldevicemanagement/searchdevices',
              text: this.$t('menu.searchDevices'),
              icon: 'search',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
            },
            {
              type: 'link',
              to: '/centraldevicemanagement/unconfigured',
              text: this.$t('menu.unconfiguredDevices'),
              icon: 'question',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
            },
            {
              type: 'link',
              to: '/centraldevicemanagement/bulk-command-list',
              text: this.$t('menu.bulkCommand'),
              icon: 'terminal',
              toggle: true,
              isHidden: this.authenticationHasRole('zerolevel')
            },
            {
              type: 'link',
              to: '/centraldevicemanagement/devicereport',
              text: this.$t('menu.deviceReport'),
              icon: 'hdd',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
            }
          ]
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('menu.mediaReview'),
          icon: 'photo-video',
          toggle: false,
          target: 'mediaReviewSubmenu',
          isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'list',
          id: 'mediaReviewSubmenu',
          items: [
            {
              type: 'link',
              to: '/photopoint-media-review',
              text: 'Photopoint',
              icon: 'camera',
              toggle: true,
            },
            {
              type: 'link',
              to: '/video-media-review',
              text: 'Video',
              icon: 'video',
              toggle: true,
            },
            {
              type: 'link',
              to: '/photo-media-review',
              text: 'Photo',
              icon: 'image',
              toggle: true,
            },
            {
              type: 'link',
              to: '/media-review-report',
              text: 'Report',
              icon: 'receipt',
              toggle: true,
            }
          ]
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('reports'),
          icon: 'receipt',
          toggle: false,
          target: 'reportsSubmenu',
          isHidden: !this.authenticationHasRole('scope_staff')
        },
        {
          type: 'list',
          id: 'reportsSubmenu',
          items: [
          {
              type: 'link',
              to: '/customer-worklog-overview',
              text: this.$t('customerWorklogOverview'),
              icon: 'table',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_customer_worklog'))
            },
            {
              type: 'link',
              to: '/customer-worklog-report',
              text: this.$t('customerWorklogReport'),
              icon: 'table',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_customer_worklog'))
            },
             {
              type: 'link',
              to: '/employee-worklog-report',
              text: this.$t('employeeWorklogReport'),
              icon: 'table',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_customer_worklog'))
            },
            {
              type: 'link',
              to: '/home-office-report',
              text: 'Home Office Report',
              icon: 'table',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_hr'))
            },
            {
              type: 'link',
              to: '/vacation-report',
              text: 'Vacation Report',
              icon: 'table',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_hr'))
            },
            {
              type: 'link',
              to: '/time-compensation-report',
              text: 'Time Compensation Report',
              icon: 'clock',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_hr'))
            },
            {
              type: 'link',
              to: '/licence-report',
              text:this.$t('softwareComponent.licencesReport'),
              icon: 'id-card-alt',
              toggle: true,
              isHidden: !this.authenticationHasRole('scope_staff')
            },
            {
              type: 'link',
              to: '/software-components',
              text:this.$t('softwareComponent.softwareComponent'),
              icon: 'hotel',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin') || this.authenticationHasRole('firstlevel')))
            },
            {
              type: 'link',
              to: '/problem/skimovie',
              text: this.$t('menu.skimovieProblems'),
              icon: 'video-slash',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
            },
            {
              type: 'link',
              to: '/problem/mismatchedstates',
              text: this.$t('menu.mismatchedStates'),
              icon: 'thermometer-quarter',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
            },
            {
              type: 'link',
              to: '/login-data-audit',
              text: this.$t('menu.loginDataAudit'),
              icon: 'lock',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && 
              (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin') || this.authenticationHasRole('firstlevel') &&
              (this.authenticationHasRole('logindata_DestOS_backend') || this.authenticationHasRole('logindata_DestOS_infra') || this.authenticationHasRole('logindata_all'))))
            },
            {
              type: 'link',
              to: '/command-audit',
              text: 'Command Audit',
              icon: 'lock',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && 
              (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin') || this.authenticationHasRole('firstlevel') &&
              (this.authenticationHasRole('logindata_DestOS_backend') || this.authenticationHasRole('logindata_DestOS_infra') || this.authenticationHasRole('logindata_all'))))
            },
          ]
        },
        {
          type: 'hr',
        },
        {
          type: 'link',
          to: '/on-duty-calendar',
          text: this.$t('onCallDuty'),
          icon: 'calendar-alt',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff'))
        },
        {
          type: 'link',
          text: this.$t('eventCalendar'),
          to: '/event-calendar',
          icon: 'calendar-plus',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff'))
        },
        {
          type: 'link',
          to: '/delivery-calendar',
          text: this.$t('deliveryCalendar'),
          icon: 'truck',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff'))
        },
        {
          type: 'link',
          to: '/rollout-calendar',
          text: this.$t('rolloutCalendar'),
          icon: 'scroll',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff'))
        },
        {
          type: 'link',
          to: '/resource-project-calendar',
          text: this.$t('menu.resourceProjectCalender'),
          icon: 'people-carry',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff'))
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('menu.videoPostprocessing'),
          icon: 'tv',
          toggle: false,
          target: 'videoPostprocessingSubmenu',
          isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('view_skimovie') && 
          (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('admin')))
        },
        {
          type: 'list',
          id: 'videoPostprocessingSubmenu',
          items: [
            {
              type: 'link',
              to: '/videopostprocessing/theme',
              text: this.$t('menu.themes'),
              icon: 'clone',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/introoutro',
              text: this.$t('menu.introsOutros'),
              icon: 'film',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/cutconfig',
              text: this.$t('menu.cutConfiguration'),
              icon: 'cut',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/installationconfig',
              text: this.$t('menu.installationConfiguration'),
              icon: 'cogs',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/sounds',
              text: this.$t('menu.sounds'),
              icon: 'music',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/fonts',
              text: this.$t('menu.fonts'),
              icon: 'font',
              toggle: true
            },
            {
              type: 'link',
              to: '/videopostprocessing/collections',
              text: this.$t('menu.collections'),
              icon: 'images',
              toggle: true
            }
          ]
        },
        {
          type: 'link',
          to: '/customer-notification',
          text:this.$t('menu.customerNotification'),
          icon: 'envelope-open-text',
          toggle: true,
          isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('menu.management'),
          icon: 'envelope',
          toggle: false,
          target: 'smsManagementSubmenu',
          isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
        },
        {
          type: 'list',
          id: 'smsManagementSubmenu',
          items: [
            {
              type: 'link',
              to: '/smsmanagement/template',
              text: this.$t('menu.templates'),
              icon: 'clone',
              toggle: true
            },
            {
              type: 'link',
              to: '/smsmanagement/incomplete',
              text: this.$t('menu.incompleteTemplates'),
              icon: 'tasks',
              toggle: true
            },
            {
              type: 'link',
              to: '/acknowledgemessages',
              text: this.$t('menu.acknowledgeMessages'),
              icon: 'band-aid',
              toggle: true,
            },
            {
              type: 'link',
              to: '/bulk-commands',
              text: this.$t('menu.bulkCommands'),
              icon: 'terminal',
              toggle: true,
            },
          ]
        },
        {
          type: 'parent',
          tag: 'li',
          to: '',
          text: this.$t('menu.settings'),
          icon: 'cogs',
          toggle: false,
          target: 'settingsSubmenu',
          isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('admin') || this.authenticationHasRole('secondlevel')))
        },
        {
          type: 'list',
          id: 'settingsSubmenu',
          items: [
            {
              type: 'link',
              to: '/applicationsettings',
              text: this.$t('menu.applicationSettings'),
              icon: 'cogs',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
            },
            {
              type: 'link',
              to: '/applicationinfo',
              text: this.$t('menu.applicationInformation'),
              icon: 'cog',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
            },
            {
              type: 'link',
              to: '/installation-questions',
              text:this.$t('menu.installationQuestions'),
              icon: 'question',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('admin') || this.authenticationHasRole('secondlevel')))
            },
            {
              type: 'link',
              to: '/software-component-types',
              text: 'Software Component Types',
              icon: 'hotel',
              toggle: true,
              isHidden: !(this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))
            }
          ]
        }
      ];
    }
  },
  created () {
    window.addEventListener('resize', this.computeDisplayLogout);
    this.computeDisplayLogout();
  },
  beforeDestroy () {
    this.$eventBus.$off('toggleAsideMenu');
    window.removeEventListener('resize', this.computeDisplayLogout);
  },
  methods: {
    computeDisplayLogout () {
      if (this.mobileMixin_isMobile === true) {
        this.displayLogout = true;
        return;
      }
      if (this.mobileMixin_isSmallScreen() === true) {
        this.displayLogout = true;
        return;
      }
      this.displayLogout = false;
    },
    toggleAsideMenu () {
      this.$eventBus.$emit('toggleAsideMenu');
    },
    toggleAsideMenuMobile () {
      this.$eventBus.$emit('toggleAsideMenuMobile');
    },
    openInGrafana () {
      this.toggleAsideMenuMobile();
      window.open('https://grafana-myservices.production.aws.skiline.cc/', '_blank');
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li:hover {
  background: rgb(219, 219, 219);
}
i {
  color: #a8aabb;
}
li a {
  color: #3e3e4d;
  display: inline-block;
  width: 100%;
}
li.exactActiveClass {
  background: rgba(0,0,0,0.04);
  color: #1f9fd9;
}
li.exactActiveClass i {
  color: #1f9fd9;
}
.collapsed[data-toggle="collapse"] i.submenuArrow {
  -webkit-transform: rotate(180deg);
  -moz-transform:    rotate(180deg);
  -ms-transform:     rotate(180deg);
  -o-transform:      rotate(180deg);
  transform:         rotate(180deg);
}
.m-aside-left--minimize li span {
  display: none;
}
.mobileAsideMenu .mainMenu {
  left: -100%;
  top: 61px;
}
.m-aside-left--on .mainMenu {
  left: 0;
}
.mobileAsideMenu .mainMenu {
  width: 100% !important;
}
a {
  text-decoration: none;
}

.mainMenu {
  overflow: scroll;
}

.number{
  height: 22px;
  width:  22px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  right: 20px;
  border-style: solid;
  border-width: 2px;
}

.number:empty {
   display: none;
}
</style>
